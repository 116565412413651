import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "p-col-12 p-md-4 p-3 bg-white mb-1 rounded " }
const _hoisted_2 = { class: "grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4" }
const _hoisted_3 = { class: "bg-white px-6 py-4 rounded-lg w-full" }
const _hoisted_4 = { class: "desc" }
const _hoisted_5 = { class: "font-bold mb-2 lg:text-xl md:text-xl text-base" }
const _hoisted_6 = { class: "color-blue-grey mb-5 lg:text-base md:text-base text-sm sm-truncate-2" }
const _hoisted_7 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputIconRightGrey = _resolveComponent("InputIconRightGrey")
  const _component_Paginator = _resolveComponent("Paginator")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_InputIconRightGrey, {
        placeholder: "Cari Disini ...",
        type: _ctx.$enumBase.input.text,
        modelValue: _ctx.inputSearch,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputSearch = $event)),
        onKeypress: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.changeSearch(_ctx.inputSearch)), ["enter"])),
        canClick: false,
        onSubmitInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeSearch(_ctx.inputSearch))),
        icon: _ctx.$icon.svg.searchEva
      }, null, 8, ["type", "modelValue", "icon"])
    ]),
    _createVNode("section", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createBlock("div", {
          class: "flex w-full",
          key: 'report-' + index
        }, [
          _createVNode("div", _hoisted_3, [
            _createVNode("img", {
              src: _ctx.iconPath,
              class: "lg:w-18 md:w-16 w-10 mb-4"
            }, null, 8, ["src"]),
            _createVNode("div", _hoisted_4, [
              _createVNode("h6", _hoisted_5, _toDisplayString(item.Title), 1),
              _createVNode("p", _hoisted_6, _toDisplayString(item.Description), 1),
              _createVNode("p", {
                class: "cursor-pointer color-primary hover-primary font-bold lg:text-base md:text-base text-sm",
                onClick: ($event: any) => (_ctx.showDetailReport(item))
              }, " Details & Download ", 8, ["onClick"])
            ])
          ])
        ]))
      }), 128))
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode(_component_Paginator, {
        rows: _ctx.filters.pageSize,
        totalRecords: _ctx.filters.totalRecords,
        onPage: _ctx.onPage
      }, null, 8, ["rows", "totalRecords", "onPage"])
    ])
  ]))
}
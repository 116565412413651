import store from '@/store'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'
import moment from 'moment'

const module = 'appActiveUser'
const moduleGetter = store.getters[`${module}/getAppActiveUser`]

type TinputData = {
  TanggalMulai: Date,
  TanggalAkhir: Date
}

const formingPayloadGenerateReport = (datas: any, inputData: TinputData | null = null) => {
  // console.log('datas', datas)
  // console.log('store', moduleGetter)
  const obj = {} as any
  datas.forEach((data: any) => {
    if (data.Name === '@IdAgen') {
      obj[data.Name] = checkPermission(PermissionEnum.ListAllReports) ? 'all' : moduleGetter.agent?.id
    } else if (data.Name === '@IdUser') {
      obj[data.Name] = moduleGetter.id
    } else if (data.Name === '@IdKurir') {
      obj[data.Name] = 'all'
    } else if (data.Name === '@TanggalMulai') {
      obj[data.Name] = inputData?.TanggalMulai
    } else if (data.Name === '@TanggalAkhir') {
      obj[data.Name] = inputData?.TanggalAkhir
    }
  })
  console.log('obj', obj)
  return obj
}

export {
  formingPayloadGenerateReport
}
